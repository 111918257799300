import { SelectDos } from "../plugins/select2";
import {
    getData,
    CSRF,
    setSelect2Ajax,
    defaultSelect2,
    b64Uri,
    getJson,
} from "../helpers";

export const dataSupplier = { companies: [] };

/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class SelectSupplier extends HTMLSelectElement {
    async connectedCallback() {
        let management = getData(this, "data-management");

        let configSelect = {
            ajax: {
                url: getData("suppliersUrlSelect"),
                type: "post",
                dataType: "json",
                cache: true,
                data: function (param) {
                    return {
                        _token: CSRF,
                        search: param.term,
                        management: management,
                        companies: dataSupplier.companies,
                        // limit: 10,
                    };
                },
                processResults: function (response) {
                    // response.forEach((element) => {
                    //     element.id = element.alpha2;
                    // });
                    return {
                        results: response,
                    };
                },
            },
            // templateResult: (data) => ucwords(data.text),
            // templateSelection: (data) => {
            //     if (!data.id) {
            //         return data.text;
            //     }
            //     return `${data.alpha2}`;
            // },
        };

        const SD = new SelectDos(configSelect);
        const S = await SD.selectDos(this);

        S[0]?.addEventListener("aj:select2", (e) => {
            const Data = e.detail.data;

            if (S[0].hasAttribute("multiple")) {
                dataSupplier.companies.push(Data.id);
            } else {
                dataSupplier.companies = [Data.id];
            }
        });

        S[0]?.addEventListener("aj:unselecting", (e) => {
            const Data = e.detail.data;
            dataSupplier.companies = dataSupplier.companies.filter(
                (item) => item != Data.id
            );
        });

        if (getData(this, "data-default")) {
            let def = getJson(b64Uri(getData(this, "data-default"), "decode"));
            if (def?.id) {
                // setSelect2Ajax(this, def.id, def.number + " - " + def.name);
                setSelect2Ajax(this, def.id, `${def.name} (${def.cif})`);
            }
        }
        if (getData(this, "data-selected")) {
            defaultSelect2(
                this,
                getData(this, "data-selected"),
                "suppliersUrlSelect"
            );
        }
    }
}

window.customElements.define("select-supplier", SelectSupplier, {
    extends: "select",
});
