import { SelectDos } from "../plugins/select2";
import {
    getData,
    CSRF,
    getJson,
    b64Uri,
    setSelect2Ajax,
    defaultSelect2,
} from "../helpers";
// import ucwords from "ucwords";

/**
 * Implementar el plugin Select2 para las empresas
 *
 */
export class SelectBalance extends HTMLSelectElement {
    connectedCallback() {
        let configSelect = {
            ajax: {
                url: getData("balancesUrlSelect"),
                type: "post",
                dataType: "json",
                cache: true,
                data: function (param) {
                    return {
                        _token: CSRF,
                        search: param.term,
                        balance: getData(this[0], "data-balance"),
                    };
                },
                processResults: function (response) {
                    return {
                        results: response,
                    };
                },
            },
        };

        const SD = new SelectDos(configSelect);
        SD.selectDos(this);

        if (getData(this, "data-default")) {
            let def = getJson(b64Uri(getData(this, "data-default"), "decode"));
            if (def?.id) {
                setSelect2Ajax(this, def.id, def.number + " - " + def.name);
            }
        }
    }
}

window.customElements.define("select-balance", SelectBalance, {
    extends: "select",
});
